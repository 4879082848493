import {ImageFiltersPayload} from '~api/images';

import {UNKNOWN_MODEL_ID} from 'src/constants/constants';

export interface ImagesData {
  data: ImageData[];
  total?: number;
  actions: Partial<PaginationActions> | null;
  cursor: string | null;
}

export interface ImageUploadData {
  imageFiles: {
    date?: number;
    fileId: string;
    fileType: string;
    label?: string;
    url: string;
    urlPresigned: string;
  }[];
}

export interface ImageData {
  id: string;
  annotations: LatestAnnotations;
  data: string | null;
  thumbData: string | null;
  key: string | null; // null when no image is available
  device: string;
  meta: ImageMetadata;
  tags: string[];
  trainingTags: string[];
  timestamp: number;
  /**
   * only available if filters.includeAnnotationHistory === true
   */
  humanAnnotationHistory: null | PerModelAnnotationHistory;
  /**
   * only available if filters.sessionTag is set and the request is for a smart teach session
   */
  smartSessionAnnotations: null | {
    [modelId: string]: ImageDataAnnotation;
  };
}

export type ImageDataAlias = ImageData;

export interface ImageDataWithAnnotationHistory extends ImageData {
  humanAnnotationHistory: PerModelAnnotationHistory;
}

export interface LatestAnnotations {
  // All models are included, even if they have no annotations
  [modelId: string | typeof UNKNOWN_MODEL_ID]: {
    latestHumanAnnotation: ImageDataAnnotation | null;
    machinePrediction: ImageDataAnnotation | null;
  };
}

export interface PerModelAnnotationHistory {
  // All models are included, even if they have no annotations
  // annotations are sorted by newest first
  [modelId: string]: ImageDataAnnotation[] | null;
}

export type AnnotationType = 'machine' | 'human' | 'smart_session' | 'anomaly_session';

export interface ImageDataAnnotation {
  createdAt: number;
  updatedAt: number;
  model: boolean;
  user: string;
  trainingTags: string[];
  modelId: string;
  type: AnnotationType;
  labels: AnnotationLabel[];
  id?: string; // not set if type === 'machine'
  inferenceId?: string; // only set if type === 'machine'
  isDefaultPrediction?: boolean; // only set if type === 'machine'
  smartSessionId?: string; // only set if type === 'smart_session' and annotation documents that existed before introducing anomaly sessions
  sessionTag?: string; // only set if type === '*_session'
  automlPipelineIds?: string[];
}

/**
 * polygonId is `null` for model annotations
 */
export type PolygonId = string | null;

export interface AnnotationLabel {
  id: LabelItem['id'];
  /**
   * Polygon coordinates. This is the only reliable indicator for distinguishing type "class" or "polygon" labels.
   * If this is null, the AnnotationLabel is a class label.
   */
  coordinates: Coordinate[] | null;
  /**
   * The edge device doesn't set a type, so this can be null even though it should be "polygon".
   */
  type: 'polygon' | null;
  /**
   * References a polygon when the annotation label is pinned
   */
  polygonId?: PolygonId;
  probability?: number | null;

  hiddenForSmartSession?: boolean;
}

// Narrowed types for stricter differentiation between class and polygon labels
export type PolygonAnnotationLabel = AnnotationLabel & {coordinates: Coordinate[]; type: 'polygon'};
export type ClassAnnotationLabel = Omit<AnnotationLabel, 'coordinates' | 'type' | 'polygonId'>;
export type PolygonOrClassAnnotationLabel = PolygonAnnotationLabel | ClassAnnotationLabel;

export interface CreateAnnotationPayloadV2<T = PolygonAnnotationLabel | ClassAnnotationLabel> {
  key: string; // ImageData key
  label: T[];
}

export interface ImageLabelPayload {
  id: string;
  type: LabelType | null;
  coordinates?: Coordinate[] | null;
  polygonId?: PolygonId;
}

export interface ImageLabelPayloadByModel {
  [modelId: string]: ImageLabelPayload[];
}

export interface UpdateImagesDataPayloadItem {
  imageId: string;
  labelsByModel?: ImageLabelPayloadByModel;
}

export type UpdateImagesDataPayload = UpdateImagesDataPayloadItem[];

export interface UpdateImagesDataResponseItem {
  imageId: string;
  annotations: LatestAnnotations;
  humanAnnotationHistory?: PerModelAnnotationHistory;
}

export type UpdateImagesDataResponse = UpdateImagesDataResponseItem[];

interface UploadImageItem {
  fileChecksum: string;
  perspectiveId: number;
  productType: string;
  tags: string[];
  url: string;
}
export type UploadImagePayload = {
  allowDuplicateImageFiles?: boolean;
  images: UploadImageItem[];
};

interface UploadImagesGenerateUrlResponseItem {
  date?: number;
  fileId: string;
  fileType: ImageTypesEnum;
  label?: string;
}
[];

export type UploadImagesGenerateUrlResponse = {
  payload: UploadImagesGenerateUrlResponseItem[];
  files: File[];
};

export type LabelType = 'class' | 'polygon';
export type Condition = 'OK' | 'NOK' | 'OTHER';

export interface LabelItem {
  id: string;
  type: LabelType;
  condition: Condition;
  colorCode: string;
  deleted?: boolean;
  alias?: string | null;
}

export interface UnsavedLabelItem extends Omit<LabelItem, 'id' | 'alias'> {
  alias: string;
  id?: string;
  /**
   * A predefined class cannot be deleted by the user
   */
  predefined?: boolean;
}

export type NewOrExistingLabelItem = UnsavedLabelItem | LabelItem;

export interface ReduxStateErrors {
  [type: string]: string;
}

export type PaginationActions = {[K in PaginationActionName]: PaginationAction};

export interface ImageMetadata {
  productType?: string;
  serialNumber?: string;
  probability?: number;
  perspective?: number;

  [key: string]: any;
}

export interface ImageMeta {
  imageId: string;
  meta?: ImageMetadata;
}

export type ImageTypesEnum = '.jpg' | '.jpeg';

export type ToolBarTimeStamp = {from: number; to: number};

/**
 * Adapt the filters the API accepts to the filters the frontend provides and keeps track of in the query string
 */
export interface ImageFilters
  extends Omit<ImageFiltersPayload, 'labelers' | 'includeAnnotationHistory' | 'sessionTag'> {
  orderBy?: ImageOrderBy; // unifies sortBy/sortOrder
  confusionMatrixCellID?: string;
}

export interface PolygonHeatmapData {
  perspective: number | null;
  polygonCount: number;
  polygonHeatmap: string;
}

export interface PaginationAction {
  name: PaginationActionName | null;
  cursor: string;
  pageOffset?: number;
}

export interface PaginationActionWithTargetPage {
  action: PaginationAction;
  targetPage: number | undefined;
}

export enum PaginationActionName {
  NEXT = 'next',
  PREV = 'prev',
  JUMP = 'jump',
}

export enum ImageUploadActionName {
  UPLOAD = 'upload',
  CANCEL = 'cancel',
}

export type ImageSortBy = 'SERIAL_NUMBER' | 'TIMESTAMP' | 'ANNOTATION_TIMESTAMP' | 'PROBABILITY' | 'RANDOM';
export type ImageSortOrder = 'ASC' | 'DESC';

// These are all image orderings defined for the teach API
export enum ImageOrderBy {
  // The enum values are structured as follows: {SortBy}{IMAGE_SORT_ORDER_DELIMITER}{SortOrder}.
  TIMESTAMP_DESC = 'TIMESTAMP__DESC',
  TIMESTAMP_ASC = 'TIMESTAMP__ASC',
  RANDOM = 'RANDOM',
  ANNOTATION_TIMESTAMP_DESC = 'ANNOTATION_TIMESTAMP__DESC',
  ANNOTATION_TIMESTAMP_ASC = 'ANNOTATION_TIMESTAMP__ASC',
  PROBABILITY_DESC = 'PROBABILITY__DESC',
  PROBABILITY_ASC = 'PROBABILITY__ASC',
  SIMILARITY__ASC = 'SIMILARITY__ASC',
  SIMILARITY__DESC = 'SIMILARITY__DESC',
  SERIAL_NUMBER_ASC = 'SERIAL_NUMBER__ASC',
  SERIAL_NUMBER_DESC = 'SERIAL_NUMBER__DESC',
}

export type ViewMode = 'image' | 'instance';

export interface Coordinate {
  x: number;
  y: number;
}

export type Dimensions = [width: number, height: number];

/**
 * Metadata associated with a polygon. Some of the data is provided by the annotation data, some is
 * provided by the fabric.Polygon object.
 */
export interface PolygonMetadata {
  // Unique identifier calculated from the polygon's metadata, used to identify the polygon and its
  // associated LabelChip
  labelChipId: string;

  // Metadata that uniquely identifies the polygon
  points: Coordinate[];
  userEmail: string;
  annotationType: AnnotationType;
  labelId?: string;
  modelId?: string;

  // used to display additional information about the label
  updatedAt: number;
  color: string;
  polygonId?: PolygonId;
  probability?: number;

  // This is used to determine whether the polygon is hidden in the smart session, which is the case
  // for all polygons with a label that is not the session label
  hiddenForSmartSession?: boolean;

  // The "visible" property is defined by the fabric.js Object class and must not be renamed
  visible: boolean;
}

export interface LabelInstancePaginationResponse {
  cursor: string;
  actions: PaginationActions;
  data: LabelInstance[];
  total: number | null;
}

export interface LabelInstance {
  id: string;
  label: string;
  data: string; // Base64 string, that decodes to an SVG which holds the polygon coordinates as SVG polygon and the cropped image as background url
  imageId: string;
  timestamp: number; // human annotation timestamp, NOT image timestamp
  type: LabelType;
  automlPipelineIds?: string[];
  polygonId?: PolygonId;
}
