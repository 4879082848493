import {MutableRefObject, useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {ImageData, LabelInstance} from '~redux/types/images';

export function useCheckedItems<T extends ImageData | LabelInstance>(
  currentPageItemsData: T[] | undefined,
  bustCache: MutableRefObject<boolean>,
): [
  T[],
  {
    isChecked: (id: string) => boolean;
    setChecked: (id: string, checked: boolean) => void;
    setAllChecked: (checked: boolean) => void;
    isAllChecked: boolean;
    setMultipleChecked: (imageList: T[]) => void;
    clearChecked: () => void;
    hasOnlyImagesChecked: boolean;
    resetCache: () => void;
  },
  bustCache: MutableRefObject<boolean>,
] {
  const [itemCache, setItemCache] = useState<CheckedItemsCache>({});
  const itemsDataRef = useRef<T[]>([]);
  const checkedItems = itemsDataRef.current.filter((item) => itemCache[item.id]?.checked);

  useEffect(() => {
    if (!currentPageItemsData || !currentPageItemsData.length) return;
    if (bustCache.current) {
      bustCache.current = false;
      const newCache = currentPageItemsData.reduce((acc, item) => {
        const key = 'key' in item ? item.key : item.imageId;
        acc[item.id] = {checked: false, hasImage: Boolean(key)};
        return acc;
      }, {} as CheckedItemsCache);
      setItemCache(newCache);
      itemsDataRef.current = [...currentPageItemsData];
      return;
    }

    const dataMap = new Map(itemsDataRef.current.map((item) => [item.id, item]));
    currentPageItemsData.forEach((item) => {
      dataMap.set(item.id, {...dataMap.get(item.id), ...item});
    });
    itemsDataRef.current = Array.from(dataMap.values());

    // Update cache with new or updated items while maintaining existing checked states
    const updatedCache = itemsDataRef.current.reduce((cache, item) => {
      const key = 'key' in item ? item.key : item.imageId;
      cache[item.id] = itemCache[item.id]
        ? {...itemCache[item.id], hasImage: Boolean(key)}
        : {checked: false, hasImage: Boolean(key)};
      return cache;
    }, {} as CheckedItemsCache);
    setItemCache(updatedCache);

    // we do not want to update again itemCache if it itself changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bustCache.current, currentPageItemsData]);

  const isChecked = useCallback((id: string) => itemCache[id]?.checked || false, [itemCache]);

  const setChecked = useCallback((id: string, checked: boolean) => {
    setItemCache((prev) => ({
      ...prev,
      [id]: {...prev[id], checked},
    }));
  }, []);

  const setAllChecked = useCallback(
    (checked: boolean) => {
      setItemCache((prev) => {
        const newCache = {...prev};
        currentPageItemsData?.forEach((item) => {
          newCache[item.id] = {...newCache[item.id], checked};
        });
        return newCache;
      });
      itemsDataRef.current = [...(currentPageItemsData || [])];
    },
    [currentPageItemsData],
  );

  const isAllChecked = useMemo(() => {
    if (!currentPageItemsData) return false;
    const currentPageIds = currentPageItemsData.map((item) => item.id);
    return currentPageIds.length > 0 && currentPageIds.every((id) => itemCache[id]?.checked);
  }, [currentPageItemsData, itemCache]);

  const setMultipleChecked = useCallback((imageList: T[]) => {
    const newCache = imageList.reduce((acc, item) => {
      const key = 'key' in item ? item.key : item.imageId;
      acc[item.id] = {checked: true, hasImage: Boolean(key)};
      return acc;
    }, {} as CheckedItemsCache);

    setItemCache(newCache);
    itemsDataRef.current = [...imageList];
  }, []);

  const clearChecked = useCallback(() => {
    setItemCache((prev) => {
      const newCache = {...prev};
      Object.keys(newCache).forEach((key) => {
        newCache[key] = {...newCache[key], checked: false};
      });
      return newCache;
    });
  }, []);

  const resetCache = useCallback(() => {
    bustCache.current = true;
  }, [bustCache]);

  const hasOnlyImagesChecked = useMemo(() => {
    return Object.values(itemCache).every((item) => !item.checked || item.hasImage);
  }, [itemCache]);

  return [
    checkedItems,
    {
      isChecked,
      setChecked,
      setAllChecked,
      isAllChecked,
      setMultipleChecked,
      hasOnlyImagesChecked,
      clearChecked,
      resetCache,
    },
    bustCache,
  ];
}
