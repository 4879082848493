import {useRouter} from 'next/router';
import {createContext, ReactNode, useContext, useEffect, useRef, useState} from 'react';

import {Route} from '~utils/routeUtil';
import {ImageDataAlias, LabelInstance} from '~redux/types/images';
import {selectImages} from '~redux/reducers/imageReducer';
import {useAppSelector} from '~redux/index';

import {useCheckedItems} from './checkedItems.hooks';

export const CombinedCheckedItemsContext = createContext<CombinedCheckedItemsContextValue>(
  {} as CombinedCheckedItemsContextValue,
);

export const useCombinedCheckedItems = () => {
  return useContext(CombinedCheckedItemsContext) as CombinedCheckedItemsContextValue;
};

export const CombinedCheckedItemsProvider = (props: {children: ReactNode}) => {
  const [labelInstances, setLabelInstances] = useState<LabelInstance[]>()
  const images = useAppSelector(selectImages);
  const checkedImagesNeedRefresh = useRef(false);
  const checkedImages = useCheckedItems<ImageDataAlias>(images, checkedImagesNeedRefresh);

  const checkedLabelInstancesNeedRefresh = useRef(false);
  const checkedLabelInstances = useCheckedItems<LabelInstance>(
    labelInstances,
    checkedLabelInstancesNeedRefresh,
  );

  const router = useRouter();
  const previousRoute = useRef<string | null>(null);

  useEffect(() => {
    const handleRouteChange = (currentRoute: Route) => {
      const shouldCacheBeKept =
        (currentRoute.includes(Route.archive) && previousRoute.current?.includes(Route.archive)) ||
        (currentRoute.includes(Route.modelSamples) && previousRoute.current?.includes(Route.modelSamples)) ||
        (currentRoute.includes(Route.modelAddSamples) && previousRoute.current?.includes(Route.modelAddSamples)) ||
        (currentRoute.includes(Route.modelFinetune) && previousRoute.current?.includes(Route.modelFinetune)) ||
        previousRoute.current === null;

      if (!shouldCacheBeKept) {
        const checkedImagesActions = checkedImages[1];
        checkedImagesActions.clearChecked();
        const checkedLabelInstancesActions = checkedLabelInstances[1];
        checkedLabelInstancesActions.clearChecked();
      }

      previousRoute.current = currentRoute;
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [checkedImages, checkedLabelInstances, router.events]);

  const value: CombinedCheckedItemsContextValue = {
    checkedImages,
    checkedLabelInstances,
    setLabelInstances
  };

  return <CombinedCheckedItemsContext.Provider value={value}>{props.children}</CombinedCheckedItemsContext.Provider>;
};
