import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {createSelector} from 'reselect';

import {CLASS_EMPTY, CLASS_OK, LABEL_ID_SEPARATOR, UNKNOWN_MODEL_ID} from '~constants/constants';
import {theme} from '~constants/theme';
import {byOldestClassificationOrAnnotationFirst, getUUID} from '~utils/miscUtils';
import {RESET} from '~redux/types';
import {Coordinate, ImageData, ImageLabelPayloadByModel, LabelItem, LabelType} from '~redux/types/images';
import {InspectionType, Model} from '~redux/types/models';
import {PartialWithRequired} from '~redux/types/utils';
import {
  addAnnotationItem,
  createAnnotationItem,
  deleteAnnotationItem,
  getModelIdForAnnotationItem,
  offsetCoordinates,
  updateAnnotationItem,
} from '~redux/reducers/annotatorReducer.utils';
import {ReduxState} from '~redux/index';

import {
  GroupedAnnotationItems,
  groupPolygonItemsByProximity,
} from '~components/images/annotator/utils/groupLabelChips.utils';

interface CopiedPolygonData {
  imageId: string;
  polygon: PolygonItem;
}

// The InteractionMode defines the allowed user interactions with polygons on the canvas
export type InteractionMode = 'enabled' | 'disabled' | 'drawing';

export interface AnnotationItem {
  id: string;
  labelId: string;
  color: LabelItem['colorCode'];
  alias: LabelItem['alias'];
  // modelId can be undefined for unknown machine prediction labels
  modelId: string | undefined;
  inspectionType?: InspectionType;
  type: LabelType;
  isMachinePrediction: boolean;
  isUnknownMachinePrediction?: boolean;
  userEmail: string;
  updatedAt: number; // Note: this is a unix timestamp in seconds (!) not milliseconds
  changeType: 'none' | 'new' | 'modified' | 'deleted';
  coordinates?: Coordinate[];
  probability?: number;
  hiddenForSmartSession?: boolean;
  isDefaultPrediction?: boolean;
}

export interface PolygonItem extends AnnotationItem {
  type: 'polygon';
  coordinates: Coordinate[];
}

export interface ClassificationItem extends AnnotationItem {
  type: 'class';
}

export interface PendingPolygon extends Partial<Omit<PolygonItem, 'changeType' | 'isMachinePrediction'>> {
  coordinates: PolygonItem['coordinates'];
}

export interface AnnotationItemsByModel {
  [modelId: string]: AnnotationItem[];
}

export interface AnnotatorReduxState {
  currentModel: Model | undefined;
  isImageReadOnly: boolean;
  isDrawingMode: boolean;
  showHumanAnnotations: boolean;
  showMachinePredictions: boolean;
  showLabelChips: boolean;
  annotationItems: AnnotationItemsByModel | null;
  activePolygonId: string | null;
  hoveredPolygonId: string | null;
  draggedPolygonId: string | null;
  polygonContextMenu: {
    polygonId: string | null;
    position: Coordinate;
  } | null;
  pendingPolygon: Pick<PolygonItem, 'coordinates'> | null;
  copiedPolygonData: CopiedPolygonData | null;
  unsavedChangesDialog: {
    isOpen: boolean;
    newImageIndex?: number;
  };
}

export const initialState: AnnotatorReduxState = {
  currentModel: undefined,
  isImageReadOnly: false,
  isDrawingMode: false,
  showMachinePredictions: true,
  showHumanAnnotations: true,
  showLabelChips: true,
  annotationItems: {},
  activePolygonId: null,
  hoveredPolygonId: null,
  draggedPolygonId: null,
  polygonContextMenu: null,
  pendingPolygon: null,
  copiedPolygonData: null,
  unsavedChangesDialog: {
    isOpen: false,
  },
};

const name = 'annotator';

const annotatorSlice = createSlice({
  name,
  initialState,
  reducers: {
    /**
     * Takes an image and transforms all of its latest annotation labels to AnnotationItems which is an
     * internal data structure used by the annotator. The AnnotationItems are used to render the state of the annotator
     * and keep track of changes to the annotations.
     */
    setAnnotationItems(
      state,
      action: PayloadAction<{image: ImageData | null; labelItems: LabelItem[]; models: Model[]}>,
    ) {
      const {image, labelItems, models} = action.payload;
      state.annotationItems = {};
      if (!image) {
        return;
      }

      Object.entries(image.annotations).forEach(([modelId, latestAnnotations]) => {
        const {latestHumanAnnotation, machinePrediction} = latestAnnotations;

        if (!latestHumanAnnotation && !machinePrediction) {
          return;
        }

        state.annotationItems![modelId] = [];

        latestHumanAnnotation?.labels.forEach((annotationLabel) => {
          state.annotationItems![modelId].push(
            createAnnotationItem({
              isMachinePrediction: false,
              annotationLabel,
              labelItems,
              models,
              imageDataAnnotation: latestHumanAnnotation,
            }),
          );
        });

        machinePrediction?.labels.forEach((annotationLabel) => {
          state.annotationItems![modelId].push(
            createAnnotationItem({
              isMachinePrediction: true,
              annotationLabel,
              labelItems,
              models,
              imageDataAnnotation: machinePrediction,
            }),
          );
        });
      });
    },
    resetAnnotationItems(state) {
      state.annotationItems = null;
    },
    updatePolygonItem(state, action: PayloadAction<PartialWithRequired<AnnotationItem, 'modelId' | 'id'>>) {
      const itemToUpdate = action.payload;
      const {modelId} = itemToUpdate;

      if (!state.annotationItems || !modelId || !state.annotationItems[modelId]) {
        return;
      }

      state.annotationItems[modelId] = updateAnnotationItem(itemToUpdate, state.annotationItems[modelId]);
    },
    deleteAnnotationItem(state, action: PayloadAction<AnnotationItem['id']>) {
      const annotationItemId = action.payload;
      if (!state.annotationItems) {
        return;
      }

      const modelId = getModelIdForAnnotationItem(annotationItemId, state.annotationItems);
      if (!modelId) {
        return;
      }

      state.annotationItems[modelId] = deleteAnnotationItem(annotationItemId, state.annotationItems[modelId]);
    },
    addPolygonItemFromPendingPolygon(
      state,
      action: PayloadAction<{labelItem: LabelItem; userEmail: string; modelId: string}>,
    ) {
      const {modelId, labelItem, userEmail} = action.payload;

      if (!state.pendingPolygon) {
        return;
      }
      if (!state.annotationItems) {
        state.annotationItems = {};
      }
      if (!state.annotationItems[modelId]) {
        state.annotationItems[modelId] = [];
      }

      // create and add new polygon item
      const {id, colorCode: color, alias} = labelItem;
      const itemToAdd: PolygonItem = {
        ...state.pendingPolygon,
        id: getUUID(),
        labelId: id,
        alias,
        color,
        userEmail,
        modelId,
        updatedAt: Date.now() / 1000,
        type: 'polygon',
        changeType: 'new',
        isMachinePrediction: false,
      };
      state.annotationItems[modelId] = addAnnotationItem(itemToAdd, state.annotationItems[modelId]);

      // reset pending state
      state.pendingPolygon = null;
      state.isDrawingMode = false;
    },
    addClassificationItem(state, action: PayloadAction<{labelItem: LabelItem; userEmail: string; modelId: string}>) {
      const {labelItem, userEmail, modelId} = action.payload;

      if (!state.annotationItems) {
        state.annotationItems = {};
      }

      if (!state.annotationItems[modelId]) {
        state.annotationItems[modelId] = [];
      }

      // create and add new classification item
      // Note: classification items are always considered "new", never "modified" since they are not editable
      // in the same way as polygons. They are always re-assigned by adding a new class.
      const {id, colorCode, alias} = labelItem;
      const itemToAdd: ClassificationItem = {
        id: getUUID(),
        color: colorCode,
        alias,
        labelId: id,
        userEmail,
        modelId,
        inspectionType: undefined,
        updatedAt: Date.now() / 1000,
        type: 'class',
        changeType: 'new',
        isMachinePrediction: false,
      };
      state.annotationItems[modelId] = addAnnotationItem(itemToAdd, state.annotationItems[modelId]);
    },
    updatePolygonItemLabel(
      state,
      action: PayloadAction<{
        polygonItem: PolygonItem;
        newLabel: LabelItem;
        previousModelId: string;
        newModelId: string;
      }>,
    ) {
      const {polygonItem, newLabel, previousModelId, newModelId} = action.payload;

      if (!state.annotationItems) {
        state.annotationItems = {};
      }

      if (previousModelId === newModelId) {
        // if the new label is from the same model, only update the labelId, alias, color and date
        const updatedItem = {
          id: polygonItem.id,
          labelId: newLabel.id,
          alias: newLabel.alias,
          color: newLabel.colorCode,
        };
        state.annotationItems[previousModelId] = updateAnnotationItem(
          updatedItem,
          state.annotationItems[previousModelId],
        );
      } else {
        // if the new label is from a different model, we need to:
        // 1. create a new annotation item using the existing item's data and the new label
        const newItem: AnnotationItem = {
          ...polygonItem,
          labelId: newLabel.id,
          alias: newLabel.alias,
          color: newLabel.colorCode,
          updatedAt: Date.now(),
          modelId: newModelId,
          changeType: 'new',
        };
        // 2. add the new annotation item to the new model
        state.annotationItems[newModelId] = addAnnotationItem(newItem, state.annotationItems[newModelId]);
        // 3. delete the existing annotation item from the previous model
        state.annotationItems[previousModelId] = deleteAnnotationItem(
          polygonItem.id,
          state.annotationItems[previousModelId],
        );
      }
    },
    setPendingPolygon(state, action: PayloadAction<AnnotatorReduxState['pendingPolygon']>) {
      state.pendingPolygon = action.payload;
    },
    resetPendingPolygon(state) {
      state.pendingPolygon = null;
    },
    setActivePolygonId(state, action: PayloadAction<AnnotatorReduxState['activePolygonId']>) {
      state.activePolygonId = action.payload;
    },
    resetActivePolygonId(state) {
      state.activePolygonId = null;
    },
    setHoveredPolygonId(state, action: PayloadAction<AnnotatorReduxState['hoveredPolygonId']>) {
      state.hoveredPolygonId = action.payload;
    },
    resetHoveredPolygonId(state) {
      state.hoveredPolygonId = null;
    },
    setDraggedPolygonId(state, action: PayloadAction<AnnotatorReduxState['draggedPolygonId']>) {
      state.draggedPolygonId = action.payload;
    },
    resetDraggedPolygonId(state) {
      state.draggedPolygonId = null;
    },
    showPolygonContextMenu(state, action: PayloadAction<AnnotatorReduxState['polygonContextMenu']>) {
      state.polygonContextMenu = action.payload;
    },
    hidePolygonContextMenu(state) {
      state.polygonContextMenu = null;
    },
    setMachinePredictionsVisible(state, action: PayloadAction<AnnotatorReduxState['showMachinePredictions']>) {
      state.showMachinePredictions = action.payload;
    },
    setLabelChipsVisible(state, action: PayloadAction<AnnotatorReduxState['showLabelChips']>) {
      state.showLabelChips = action.payload;
    },
    setHumanAnnotationsVisible(state, action: PayloadAction<AnnotatorReduxState['showHumanAnnotations']>) {
      state.showHumanAnnotations = action.payload;
    },
    setImageReadOnly(state, action: PayloadAction<AnnotatorReduxState['isImageReadOnly']>) {
      state.isImageReadOnly = action.payload;
    },
    setIsDrawingMode(state, action: PayloadAction<AnnotatorReduxState['isDrawingMode']>) {
      state.isDrawingMode = action.payload;
      state.activePolygonId = null;
      if (!action.payload) {
        state.pendingPolygon = null;
      }
    },
    copyPolygon(state, action: PayloadAction<AnnotatorReduxState['copiedPolygonData']>) {
      state.copiedPolygonData = action.payload;
    },
    pastePolygon(state, action: PayloadAction<{imageId: string}>) {
      // Disallow copy & pasting polygons from other models
      if (
        !state.currentModel?.id ||
        !state.copiedPolygonData ||
        state.currentModel.id !== state.copiedPolygonData.polygon.modelId
      ) {
        return;
      }

      if (!state.annotationItems) {
        state.annotationItems = {};
      }

      const {imageId, polygon} = state.copiedPolygonData;
      const newPolygonItem: PolygonItem = {
        ...polygon,
        id: getUUID(),
        changeType: 'new',
        coordinates:
          imageId == action.payload.imageId ? offsetCoordinates(polygon.coordinates, 5, 5) : polygon.coordinates,
        updatedAt: Date.now() / 1000,
      };

      state.annotationItems[state.currentModel.id] = addAnnotationItem(
        newPolygonItem,
        state.annotationItems[state.currentModel.id],
      );
    },
    setCurrentModel(state, action: PayloadAction<Model | undefined>) {
      state.currentModel = action.payload;
    },
    showUnsavedChangesDialog(state, action: PayloadAction<{newImageIndex: number}>) {
      state.unsavedChangesDialog = {
        isOpen: true,
        newImageIndex: action.payload.newImageIndex,
      };
    },
    closeUnsavedChangesDialog(state) {
      state.unsavedChangesDialog = {
        isOpen: false,
      };
    },
    /**
     * Resets the active annotation, pending changes, interactive state (hovered, active, dragged polygon)
     * and disables drawing mode. Used when the user navigates to another image and there are unsaved changes.
     */
    discardActiveAnnotation(state) {
      state.annotationItems = null;
      state.isDrawingMode = false;
      state.pendingPolygon = null;
      state.hoveredPolygonId = null;
      state.activePolygonId = null;
      state.draggedPolygonId = null;
    },
    /**
     * Resets all state except the current model and the copied polygon data. Used when leaving the annotator.
     */
    resetAllViewState(state) {
      return {
        ...initialState,
        // Keep the currentModel since it's managed by the model context
        currentModel: state.currentModel,
        // Keep the copied polygon so that it can be pasted at a later point
        copiedPolygonData: state.copiedPolygonData,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET, () => initialState);
  },
});

export const annotatorActions = annotatorSlice.actions;
export default annotatorSlice;

const select = (state: ReduxState): AnnotatorReduxState => state[name];

export const selectShowMachinePredictions = createSelector(select, (state) => state.showMachinePredictions);
export const selectShowHumanAnnotations = createSelector(select, (state) => state.showHumanAnnotations);
export const selectShowLabelChips = createSelector(select, (state) => state.showLabelChips);
export const selectIsImageReadOnly = createSelector(select, (state) => state.isImageReadOnly);
export const selectIsDrawingMode = createSelector(select, (state) => state.isDrawingMode);
export const selectCopiedPolygonData = createSelector(select, (state) => state.copiedPolygonData);
export const selectCurrentModel = createSelector(select, (state) => state.currentModel);
export const selectPendingPolygon = createSelector(select, (state) => state.pendingPolygon);
export const selectCurrentModelId = createSelector(selectCurrentModel, (model) => model?.id);
export const selectHasPendingPolygon = createSelector(selectPendingPolygon, (pendingPolygon) => !!pendingPolygon);
export const selectPolygonContextMenu = createSelector(select, (state) => state.polygonContextMenu);
export const selectActivePolygonId = createSelector(select, (state) => state.activePolygonId);
export const selectHoveredPolygonId = createSelector(select, (state) => state.hoveredPolygonId);
export const selectUnsavedChangesDialog = createSelector(select, (state) => state.unsavedChangesDialog);
export const selectAnnotationItems = createSelector(select, (state) => state.annotationItems);

export const selectActiveAnnotation = createSelector(
  [selectAnnotationItems, selectCurrentModelId],
  (annotationItems, currentModelId): AnnotationItem[] => {
    if (!currentModelId) {
      // aggregate all annotation items from all models
      return Object.values(annotationItems ?? {}).flat();
    }

    // Unknown machine prediction items are always shown, regardless of the current model
    const unknownMachinePredictionItems = annotationItems?.[UNKNOWN_MODEL_ID] ?? [];
    const currentAnnotationItems = annotationItems?.[currentModelId] ?? [];
    return [...unknownMachinePredictionItems, ...currentAnnotationItems];
  },
  {
    memoizeOptions: {
      // caches up to 10 different results, this is mostly relevant for the models that may change frequently in the archive.
      // TODO when reselect 5.0 is released, use weakMapMemoize instead (https://github.com/reduxjs/reselect#weakmapmemoizefunc---since-500)
      maxSize: 10,
    },
  },
);

export const selectDraggedPolygonId = createSelector(select, (state) => state.draggedPolygonId);

export const selectActivePolygons = createSelector([selectActiveAnnotation], (activeAnnotation): PolygonItem[] => {
  return activeAnnotation.filter(
    (label): label is PolygonItem =>
      label?.type === 'polygon' && label?.changeType !== 'deleted' && !label.hiddenForSmartSession,
  );
});

export const selectActiveClassifications = createSelector(
  [selectActiveAnnotation],
  (activeAnnotation): ClassificationItem[] => {
    return activeAnnotation.filter(
      (label): label is ClassificationItem =>
        label?.type === 'class' && label?.changeType !== 'deleted' && !label.hiddenForSmartSession,
    );
  },
);

export const selectActiveHumanPolygons = createSelector([selectActivePolygons], (polygonItems): PolygonItem[] => {
  return polygonItems.filter((polygon) => !polygon?.isMachinePrediction);
});

export const selectActiveMachinePolygons = createSelector([selectActivePolygons], (polygonItems): PolygonItem[] => {
  return polygonItems.filter((polygon) => polygon?.isMachinePrediction);
});

export const selectActiveHumanClassifications = createSelector([selectActiveClassifications], (classificationItems) => {
  return classificationItems.filter((classification) => !classification?.isMachinePrediction);
});

export const selectActiveMachineClassifications = createSelector(
  [selectActiveClassifications, selectActiveMachinePolygons],
  (classificationItems, machinePolygons) => {
    let machinePredictions = classificationItems.filter((classification) => classification?.isMachinePrediction);
    const defaultPredictions = machinePredictions.filter((prediction) => prediction.isDefaultPrediction);
    const nonDefaultPredictions = machinePredictions.filter((prediction) => !prediction.isDefaultPrediction);

    // Combine all default predictions with the same labelId prefix (OK/EMPTY) into one classification item, and afterwards create
    // an updated array of classification items that includes the combined items and the non-default predictions
    const groupedDefaultPredictions: {[groupId: string]: ClassificationItem[]} = {};
    // a) group all default predictions by labelId prefix
    defaultPredictions.forEach((prediction) => {
      // remove the unique model ID suffix to get the label (OK/EMPTY)
      const groupId = prediction.labelId.split(LABEL_ID_SEPARATOR)[0];
      if (!groupedDefaultPredictions[groupId]) {
        groupedDefaultPredictions[groupId] = [];
      }
      groupedDefaultPredictions[groupId].push(prediction);
    });

    // b) Create a new classification item for each group of default predictions
    let consolidatedDefaultPredictions = Object.entries(groupedDefaultPredictions).map(
      ([groupId, group]): ClassificationItem => {
        const latestUpdatedAt = Math.max(...group.map((item) => item.updatedAt));
        const firstItem = group[0];

        let color: string;
        if (group.length === 1) {
          color = firstItem.color;
        } else if (groupId.startsWith(CLASS_EMPTY)) {
          color = theme.palette.maddox.classEmpty;
        } else if (groupId.startsWith(CLASS_OK)) {
          color = theme.palette.maddox.classOk;
        } else {
          color = theme.palette.maddox.unlabeled;
        }

        return {
          ...firstItem,
          labelId: group.length === 1 ? firstItem.labelId : groupId,
          alias: group.length === 1 ? firstItem.alias : groupId,
          color,
          updatedAt: latestUpdatedAt,
        };
      },
    );

    // Ensure that polygons have priority over default predictions
    // a) remove all defect detection and legacy model classifications when there are defect detection or legacy model polygons
    const defectDetectionPolygons = machinePolygons.filter(
      (item) =>
        item.inspectionType === InspectionType.surfaceInspection || item.inspectionType === InspectionType.legacyModel,
    );
    if (defectDetectionPolygons.length > 0) {
      consolidatedDefaultPredictions = consolidatedDefaultPredictions.filter(
        (prediction) =>
          prediction.inspectionType !== InspectionType.surfaceInspection &&
          prediction.inspectionType !== InspectionType.legacyModel,
      );
    }

    // b) remove all object detection classifications when there are object detection polygons
    const objectDetectionPolygons = machinePolygons.filter(
      (item) => item.inspectionType === InspectionType.objectDetection,
    );
    if (objectDetectionPolygons.length > 0) {
      consolidatedDefaultPredictions = consolidatedDefaultPredictions.filter(
        (prediction) => prediction.inspectionType !== InspectionType.objectDetection,
      );
    }

    machinePredictions = [...consolidatedDefaultPredictions, ...nonDefaultPredictions];
    return machinePredictions.sort((a, b) => {
      // first criteria: labels with id starting with "OK" should appear after ids starting with "EMPTY" (or others)
      // this is important for the label chips, where the OK label should be the last one
      // which also determines the image outline color
      const aIsOk = a.labelId.startsWith(CLASS_OK);
      const bIsOk = b.labelId.startsWith(CLASS_OK);
      if (aIsOk && !bIsOk) {
        return 1;
      } else if (!aIsOk && bIsOk) {
        return -1;
      } else {
        // second criteria: sort by updatedAt
        return byOldestClassificationOrAnnotationFirst(a, b);
      }
    });
  },
);

/**
 * @returns An array of the visible classifications in the following order:
 *
 * 1. Model predictions (oldest first, 0 or more, only added if model predictions are set to be visible)
 * 2. Human annotations (oldest first, 0 or more, only added if human annotations are set to be visible)
 *
 * The last element of the array describes the classification that is currently applied to the image border, as
 * it is the newest human classification.
 *
 * If there is no data, an empty array is returned.
 */
export const selectVisibleClassifications = createSelector(
  [
    selectActiveHumanClassifications,
    selectActiveMachineClassifications,
    selectShowMachinePredictions,
    selectShowHumanAnnotations,
  ],
  (humanClassifications, machineClassifications, showMachinePredictions, showHumanAnnotations) => {
    const classifications: ClassificationItem[] = [];
    if (showMachinePredictions && machineClassifications) {
      classifications.push(...machineClassifications);
    }
    if (showHumanAnnotations && humanClassifications) {
      classifications.push(...humanClassifications);
    }
    return classifications;
  },
);

/**
 * @returns The classification that is visually applied to the image. This takes the last annotation out of
 * the list of all visible classifications, see the order of classifications defined in {@link selectVisibleClassifications}.
 */
export const selectVisuallyAppliedClassification = createSelector(
  [selectVisibleClassifications],
  (visibleClassifications) => {
    return visibleClassifications.at(-1);
  },
);

export const selectGroupedPolygonLabelChips = createSelector(
  [selectActivePolygons],
  (polygonItems): GroupedAnnotationItems => {
    return groupPolygonItemsByProximity(polygonItems);
  },
);

export const selectSavableAnnotationItems = createSelector([selectAnnotationItems], (annotationItems) => {
  if (!annotationItems) {
    return [];
  }

  return Object.values(annotationItems)
    .flat()
    .filter((item) => item.changeType !== 'none');
});

export const selectHasSavableAnnotations = createSelector(
  [selectSavableAnnotationItems],
  (annotationItems): boolean => {
    return annotationItems.length > 0;
  },
);

export const selectImageLabelPayloadByModel = createSelector(
  [selectAnnotationItems],
  (annotationItems): ImageLabelPayloadByModel => {
    // create a payload for every model that has changes
    const payloadByModel: ImageLabelPayloadByModel = {};
    Object.entries(annotationItems ?? {}).forEach(([modelId, items]) => {
      const hasChanges = items.some((item) => item.changeType !== 'none');
      if (hasChanges) {
        for (const item of items) {
          // never send machine predictions to the backend
          if (item.isMachinePrediction) {
            continue;
          }

          // create a payload for the model if it doesn't exist yet and add the item
          // we also need to create a payload if all items are deleted since we then need to send an empty array
          if (!payloadByModel[modelId]) {
            payloadByModel[modelId] = [];
          }

          // add the item to the payload if it's not deleted
          if (item.changeType !== 'deleted') {
            payloadByModel[modelId].push({
              id: item.labelId,
              coordinates: item.coordinates,
              type: item.type,
            });
          }
        }
      }
    });
    return payloadByModel;
  },
);

export const selectPolygonContextMenuData = createSelector(
  [selectActivePolygons, selectPolygonContextMenu],
  (polygons, polygonContextMenu) => {
    const polygonItem = polygons.find((polygon) => polygon.id === polygonContextMenu?.polygonId);
    if (!polygonContextMenu || !polygonItem) {
      return null;
    }
    return {
      polygonItem,
      position: polygonContextMenu.position,
    };
  },
);

export const selectActivePolygon = createSelector(
  [selectActivePolygons, selectActivePolygonId],
  (polygons, activePolygonId) => {
    return polygons.find((polygon) => polygon.id === activePolygonId) ?? null;
  },
);

export const selectDeletedHumanPolygons = createSelector([selectActiveAnnotation], (activeAnnotation) => {
  if (!activeAnnotation || Object.values(activeAnnotation).length === 0) {
    return [];
  }
  return Object.values(activeAnnotation).filter(
    (polygon) => !polygon.isMachinePrediction && polygon.changeType === 'deleted',
  );
});
