import ky, {ResponsePromise} from 'ky';

import {lowerCaseArray} from '~utils/miscUtils';
import {
  ImageMeta,
  ImagesData,
  ImageSortBy,
  ImageSortOrder,
  ImageTypesEnum,
  LabelItem,
  PaginationAction,
  PaginationActionName,
  UpdateImagesDataPayload,
  UpdateImagesDataResponse,
  UploadImagePayload,
} from '~redux/types/images';

import {mapStringNullToNull} from '~components/images/image-overview/toolbar/utils';
import {NO_DEFECTS_KEY, NO_OBJECTS_KEY} from 'src/constants/constants';
import {getOptions} from './api';

export interface ImageFiltersPayload {
  // deprecated, do not use these. They are just kept as long as the API still supports them.
  classes?: string[]; // deprecated in favor of humanAnnotationLabels
  classesToExclude?: string[]; // deprecated in favor of excludeHumanAnnotationLabels
  isLabeledByHuman?: boolean; // deprecated in favor of humanAnnotationLabels

  humanAnnotationLabels?: LabelItem['id'][];
  excludeHumanAnnotationLabels?: LabelItem['id'][];
  machinePredictionLabels?: LabelItem['id'][];
  devices?: string[];
  // isLabeledByHuman & isPredictedOnlyByModel are mutually exclusive. If both are set, isLabeledByHuman is prioritized.
  isPredictedOnlyByModel?: boolean; // currently only relevant for live teach-sessions
  withoutMachineDefectPredictions?: boolean;
  withoutMachineObjectPredictions?: boolean;
  labelers?: string[]; // checks all annotations
  latestLabelers?: string[]; // only checks latest annotations
  objectId?: string;
  objectIds?: string[];
  perspective?: number[];
  probability?: string; // "0.0-1.0" regex: ^(0\.\d+-0\.\d+)|(0\.\d+-1.0)$
  productType?: (string | null)[];
  serialNumber?: string[];
  tags?: string[];
  timeframe?: string;
  trainingTag?: string;
  withNoImages?: boolean;
  includeAnnotationHistory?: boolean;
  sessionTag?: string;
  confusionCell?: Omit<ConfusionCell, 'count' | 'imageIds'>;
  similaritySearch?: SimSearchFilter;
  evaluationCell?: EvaluationCell;
  meta?: MetadataFiltersPayload;
  automlPipelineId?: string;
  modelId?: string;
}

// This type is extracted, because we require it for caching the images data
export interface SortAndCountArgs {
  sortBy: ImageSortBy | undefined; // default: TIMESTAMP
  sortOrder: ImageSortOrder | undefined; // default: DESC
  count: number | undefined; // 1 - 10.000
}

export interface PaginationPayload extends Partial<SortAndCountArgs> {
  action?: PaginationAction;
}

export interface ImagesDataPayload {
  pagination?: PaginationPayload;
  filters?: ImageFiltersPayload;
}

const imagesEndpoint = `${process.env.AUTH_API}/images`;

export const getImagesData = (
  projectId: string,
  payload: ImagesDataPayload,
  reactQuerySignal: AbortSignal | undefined,
): Promise<ImagesData> => {
  const {filters, pagination} = payload;
  const paginatedWithCursor = {
    ...payload.pagination,
    action: {
      name: PaginationActionName.JUMP,
      ...payload?.pagination?.action,
      cursor: localStorage.getItem('cursor') || '',
    },
  };

  let productTypes;
  let meta;

  if (filters?.meta) {
    meta = mapStringNullToNull(filters.meta);
  }

  if (Array.isArray(filters?.productType)) {
    productTypes = filters.productType;
  } else if (filters?.productType) {
    productTypes = [filters.productType];
  }

  productTypes = productTypes?.reduce(
    (acc, productType) => {
      if (productType === 'null') {
        return [...acc, null];
      }
      return [...acc, productType];
    },
    [] as (string | null)[],
  );
  return ky
    .post(
      `${imagesEndpoint}/${projectId}`,
      getOptions<ImagesDataPayload>({
        apiVersion: 3,
        signalId: `getImagesData-${projectId}`,
        reactQuerySignal,
        data: {
          filters: {
            ...filters,
            machinePredictionLabels: filters?.machinePredictionLabels?.filter(
              (label) => label !== NO_DEFECTS_KEY && label !== NO_OBJECTS_KEY,
            ),
            humanAnnotationLabels: filters?.humanAnnotationLabels?.filter(
              (label) => label !== NO_DEFECTS_KEY && label !== NO_OBJECTS_KEY,
            ),
            isPredictedOnlyByModel: filters?.isPredictedOnlyByModel,
            withoutMachineDefectPredictions: filters?.withoutMachineDefectPredictions,
            withoutMachineObjectPredictions: filters?.withoutMachineObjectPredictions,
            withNoImages: Boolean(filters?.withNoImages),
            latestLabelers: lowerCaseArray(filters?.latestLabelers),
            labelers: lowerCaseArray(filters?.labelers),
            productType: productTypes,
            meta,
          },
          pagination:
            localStorage.getItem('cursor') && payload?.pagination?.sortBy === 'ANNOTATION_TIMESTAMP'
              ? paginatedWithCursor
              : pagination,
        },
      }),
    )
    .json<ImagesData>()
    .then((response) => {
      localStorage.removeItem('cursor');
      return response;
    });
};

export const putImagesData = (projectID: string, payload: UpdateImagesDataPayload) => {
  localStorage.setItem('cursor', payload[0]?.imageId);
  return ky
    .put(
      `${imagesEndpoint}/${projectID}`,
      getOptions({
        apiVersion: 3,
        signalId: 'putImagesData',
        data: payload,
      }),
    )
    .json<UpdateImagesDataResponse>();
};

export const deleteImages = (projectId: string, itemIDs: string[]): ResponsePromise => {
  // Note: all image data objects have IDs but not all have keys (the ones without an image).
  // The handling in the backend is the same for the keys and ids array, so there is no reason
  // to separate between keys and IDs here. Therefor IDs only IDs are used for consistency.
  const data = {ids: itemIDs};
  return ky.put(`${imagesEndpoint}/${projectId}/delete`, getOptions({apiVersion: 3, signalId: 'deleteImages', data}));
};

type imageUploadPayload = imageUploadPayloadItem[];
interface imageUploadPayloadItem {
  date?: number;
  fileId: string;
  fileType: ImageTypesEnum;
  label?: string;
}

export const generateImageUploadUrls = (projectId: string, imageFiles: imageUploadPayload) => {
  return ky
    .post(
      `${imagesEndpoint}/${projectId}/upload_urls`,
      getOptions({
        apiVersion: 3,
        signalId: 'imageUploadUrls',
        data: {imageFiles: imageFiles},
      }),
    )
    .json<any>();
};

export const uploadImagesToBlobStorage = (urlPresigned: string, file: ArrayBuffer) => {
  return ky.put(
    urlPresigned,
    getOptions({
      apiVersion: 3,
      signalId: 'uploadImagesToBlobStorage',
      body: file,
    }),
  );
};

export const uploadImagesUrls = (projectId: string, payload: UploadImagePayload) => {
  return ky
    .post(
      `${imagesEndpoint}/${projectId}/upload`,
      getOptions({
        apiVersion: 3,
        signalId: 'imageUploadUrls',
        data: payload,
        customHeaders: {
          'x-ms-blob-type': 'BlockBlob',
        },
      }),
    )
    .json<any>();
};

interface TagImagesPayload {
  ids: string[];
  tags: string[];
}

export const tagImages = (projectId: string, payload: TagImagesPayload): ResponsePromise => {
  return ky.put(
    `${imagesEndpoint}/${projectId}/tag`,
    getOptions({apiVersion: 3, signalId: 'tagImages', data: payload}),
  );
};

interface UntagImagesPayload {
  ids: string[];
  tags: string[];
}

export const untagImages = (projectId: string, payload: UntagImagesPayload): ResponsePromise => {
  return ky.put(
    `${imagesEndpoint}/${projectId}/untag`,
    getOptions({apiVersion: 3, signalId: 'untagImages', data: payload}),
  );
};

export const updateMeta = (projectId: string, imagesMeta: ImageMeta[]): ResponsePromise => {
  return ky.put(
    `${imagesEndpoint}/${projectId}/meta`,
    getOptions({apiVersion: 3, signalId: 'setMeta', data: imagesMeta}),
  );
};
