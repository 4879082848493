import createCache from '@emotion/cache';
import {CacheProvider} from '@emotion/react';
import {Theme, ThemeProvider} from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {useLDClient, withLDProvider} from 'launchdarkly-react-client-sdk';

import {AuthService} from '~api/auth.service';
import {getUUID} from '~utils/miscUtils';
import {getUtilStateIsLoading} from '~redux/reducers/globalReducer';
import {fetchUserData, setCurrentProjectId} from '~redux/actions/userActions';
import {reduxWrapper, useAppDispatch, useAppSelector} from '~redux/index';

import 'external-svg-loader';

import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {i18n, localeMap, useTranslation} from 'i18n';
import {NextPage} from 'next';
import {AppProps} from 'next/app';
import Head from 'next/head';
import {useRouter} from 'next/router';
import {ReactElement, ReactNode, useEffect, useState} from 'react';
import {Toaster} from 'react-hot-toast';
import {Provider} from 'react-redux';

import {PORTAL_ROOT_ID} from '~constants/constants';
import {useCurrentProjectQuery} from '~api/projects.queries';
import {
  loginRedirectQueryParam,
  modelIdQueryParam,
  projectIdQueryParam,
  replaceRoute,
  Route,
  useQueryParam,
} from '~utils/routeUtil';
import {selectCurrentProjectId, selectUserEmail} from '~redux/reducers/userReducer';

import {BuildEnvInfo} from '~components/common/BuildEnvInfo';
import {LoadingOverlay} from '~components/common/LoadingOverlay';
import {launchDarklyClientID, ThirdPartyScipts} from '~components/common/ThirdPartyScipts';
import {theme} from 'src/constants/theme';
import {updateAbility} from 'src/contexts/AbilitiesContext';
import {AbilitiesContextProvider} from 'src/contexts/AbilitiesContextProvider';
import {CombinedCheckedItemsProvider} from 'src/contexts/checked-items/CombinedCheckedItemsContext';
import {LabelInstancesProvider} from 'src/contexts/LabelInstancesContext';

declare module '@mui/material/styles' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// Create custom cache to disable CSS vendor prefixing during development
// see https://stackoverflow.com/a/65773154 and https://emotion.sh/docs/cache-provider
const emotionCache = createCache({
  key: 'maddoxai',
  ...(process.env.NODE_ENV === 'development' && {stylisPlugins: []}),
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false, // disabled in favor of ky's retry mechanism
    },
  },
});

// eslint-disable-next-line @typescript-eslint/ban-types
export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const isDevelopmentEnvironment = process.env.NODE_ENV === 'development';

const App = isDevelopmentEnvironment
  ? MaddoxAiApp
  : withLDProvider({
      clientSideID: launchDarklyClientID,
    })(MaddoxAiApp as NextPageWithLayout);

function MaddoxAiApp({Component, ...rest}: AppPropsWithLayout) {
  const {store, props} = reduxWrapper.useWrappedStore(rest);

  const getLayout = Component.getLayout || ((page) => page);
  const content = getLayout(<Component {...props} />);

  return (
    <>
      <Head>
        <title>Maddox AI</title>
        <meta name='viewport' content='minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no' />
        {/* preload common i18n file from static/locales */}
        <link rel='preload' href={`/static/locales/de-DE/common.json`} as='fetch' crossOrigin='' />
        <link rel='preload' href={`/static/locales/en/common.json`} as='fetch' crossOrigin='' />
      </Head>
      <Provider store={store}>
        <CacheProvider value={emotionCache}>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} position='top-left' />
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeMap[i18n.language || 'en']}>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <AbilitiesContextProvider>
                  <AppWrapper>
                    <CombinedCheckedItemsProvider>
                      <LabelInstancesProvider>{content}</LabelInstancesProvider>
                    </CombinedCheckedItemsProvider>
                  </AppWrapper>
                </AbilitiesContextProvider>
              </ThemeProvider>
            </LocalizationProvider>
          </QueryClientProvider>
        </CacheProvider>
      </Provider>
    </>
  );
}

function AppWrapper({children}: {children: ReactNode}) {
  const {i18n, ready: translationsReady} = useTranslation('common');
  const dispatch = useAppDispatch();
  const router = useRouter();
  const ldClient = useLDClient();

  const showLoadingSpinner = useAppSelector(getUtilStateIsLoading);
  const currentProjectId = useAppSelector(selectCurrentProjectId);
  const currentProject = useCurrentProjectQuery();
  const userEmail = useAppSelector(selectUserEmail);

  const [projectId, setProjectIdQueryParam] = useQueryParam(projectIdQueryParam);
  const [isFetchingUser, setIsFetchingUser] = useState(false);

  const isLoginPage = router.pathname === Route.login;

  useEffect(() => {
    if (AuthService.isLoggedIn() && currentProject.data && userEmail) {
      const userRole = currentProject.data.users.find((user) => user.email === userEmail)?.role;
      if (userRole) updateAbility(userRole);
    }
  }, [currentProject.data, userEmail]);

  useEffect(() => {
    const fetchUser = async () => {
      const redirectUrl = router.asPath.trim().length > 1 ? router.asPath : undefined;
      if (!AuthService.isLoggedIn() && router.pathname !== Route.login && !router.pathname.includes('503')) {
        await replaceRoute(Route.login, {query: {[loginRedirectQueryParam]: redirectUrl}});
      } else if (AuthService.isLoggedIn()) {
        try {
          setIsFetchingUser(true);
          const userData = await dispatch(fetchUserData());

          ldClient?.identify({
            kind: 'organization',
            key: getUUID('org-key'),
            customer: userData?.customer,
          });
        } catch (error) {
          await AuthService.logout({redirectUrl});
        } finally {
          setIsFetchingUser(false);
        }
      }
    };

    fetchUser();
  }, [dispatch, router.asPath, router.pathname, ldClient]);

  useEffect(() => {
    // Ensure that a projectId is set at all times (either from query param or from Redux store)
    if (projectId && typeof projectId === 'string') {
      dispatch(setCurrentProjectId(projectId));
    } else if (currentProjectId) {
      setProjectIdQueryParam(currentProjectId);
    }
  }, [dispatch, projectId, setProjectIdQueryParam, currentProjectId]);

  useEffect(() => {
    // If a model subpage is being accessed without modelID provided, it should redirect to Models page
    if (
      router.isReady &&
      // Route.modelFinetune is excluded because it might not contain modelID param
      !(router.pathname === Route.modelFinetune) &&
      ((router.pathname.includes('/model/') && router.query[modelIdQueryParam] === undefined) ||
        router.query[modelIdQueryParam] === '')
    ) {
      router.replace(Route.models);
    }

    // If the user does not have a project assigned, should redirect to the no-projects page
    if (router.isReady && currentProjectId === null && !isLoginPage) {
      router.replace(Route.noProjects);
    }
  }, [router, currentProjectId, isLoginPage]);

  useEffect(() => {
    if (document.documentElement.lang !== i18n.language) {
      document.documentElement.lang = i18n.language;
    }
  }, [i18n.language]);

  return (
    <>
      <ThirdPartyScipts />

      {!router.pathname.includes('503') &&
      (!translationsReady || isFetchingUser || (!isLoginPage && currentProjectId?.length === 0)) ? (
        <LoadingOverlay show />
      ) : (
        <>
          {children}
          <LoadingOverlay show={showLoadingSpinner} />
        </>
      )}

      <Toaster position='bottom-right' />
      <BuildEnvInfo />
      <div id={PORTAL_ROOT_ID} />
    </>
  );
}

export default App;
